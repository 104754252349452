import React, {
    useEffect,
} from 'react';
import Head from "next/head";
import type { NextPage } from 'next'
import type { AppProps } from 'next/app'
import { Type, ThemeProvider } from '@saladbob/sassafras'
import { Toaster } from 'react-hot-toast';
import '../styles/globals.scss';
import theme from '../components/theme';
import { TeamProvider } from '../components/providers/TeamProvider';
import { UserProvider } from '../components/providers/UserProvider';
import { EnvProvider } from '../components/providers/EnvProvider';
import { ModalProvider } from '../components/providers/ModalProvider';
import { Site, Menu, Page } from '../types';

export type PageProps = {
    mobileMenu: Menu[],
    menu: Menu[],
    site: Site,
    homepage?: Page,
    metaTitle?: string,
}

export type NextPageWithLayout<P = {}, IP = P> = NextPage<P, IP> & {
    getLayout?: (page: React.ReactElement, pageProps?:PageProps) => React.ReactNode,
    
}

type AppPropsWithLayout = AppProps & {
    Component: NextPageWithLayout,
}

function MyApp({
    Component,
    pageProps,
}: AppPropsWithLayout) {
    useEffect(() => {
        const style = document.getElementById('server-side-styles')
        if (style && style.parentNode) {
          style.parentNode.removeChild(style)
        }
      }, []);
    const title = `Gus Macker${pageProps.metaTitle ? ': ' + pageProps.metaTitle : ''}`
    const getLayout = Component.getLayout ?? ((page) => page);

    return (
        <>
            <Head>
                <title>{title}</title>
                <meta name="description" content="" />
                <meta name="apple-mobile-web-app-capable" content="yes" />
                <meta name="mobile-web-app-capable" content="yes" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1" />
                <link rel="manifest" href="/manifest.json" />
                {/* <link rel="preload" href={`${process.env.NEXT_PUBLIC_API_URL}/user/me`} as="fetch" crossOrigin="use-credentials" /> */}
            </Head>
            <ThemeProvider theme={pageProps.theme || theme}>
                <UserProvider>
                    <TeamProvider>
                        <EnvProvider>
                            <Type tag="div" font="p">
                                <Toaster />
                            </Type>
                            <ModalProvider />
                                {getLayout(
                                    <Component {...pageProps} />,
                                    pageProps
                                )}
                        </EnvProvider>
                    </TeamProvider>
                </UserProvider>
            </ThemeProvider>
        </>
    )
}

export default MyApp
