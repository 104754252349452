import { Theme } from '../types'

const theme: Theme = {
    colors: {
        primary: {
            process: '#da2128',
        },
        secondary: {
            process: '#1669c8',
        },
        tertiary: {
            process: '#fdf251',
        },
        red: {
            process: '#da2128',
        },
        yellow: {
            process: '#fef30a',
        },
        blue: {
            process: '#1b76bb',
        },
        darken: 'rgba(0, 0, 0, 0.15)',
        form: {
            bgColor: 'neutral50',
            txtColor: 'neutral900',
            radio: {
                checkedColor: 'primary',
                uncheckedColor: 'neutral700',
                uncheckedOutline: true,
            },
        },
    },
    fontFamilies: {
        heading: ['D-DIN', 'sans-serif'],
        body: ['D-DIN', 'sans-serif'],
        digital: ['DS-Digital', 'sans-serif'],
    },
    spacing: {
        section: '10rem',
    },
    typography: {
        giant: {
            fontFamily: 'heading',
            fontSize: '128px',
            lineHeight: '128px',
        },
        display: {
            fontFamily: 'heading',
            fontSize: '5rem',
            lineHeight: '6rem',
        },
        jumbo2: {
            fontFamily: 'heading',
            fontSize: '4rem',
            lineHeight: '4rem',
        },
        jumbo: {
            fontFamily: 'heading',
            fontSize: '3.4375rem',
            lineHeight: '4rem',
        },
        scoreBoardJumbo: {
            fontFamily: 'digital',
            fontSize: '248px',
            lineHeight: '1em',
        },
        scoreBoard: {
            fontFamily: 'digital',
            fontSize: '128px',
            lineHeight: '1em',
        },
        scoreBoardSm: {
            fontFamily: 'digital',
            fontSize: '64px',
            lineHeight: '1em',
        },
        quote: {
            fontFamily: 'body',
            fontSize: '1.75rem',
            lineHeight: '2.5rem',
            fontWeight: 'lighter'
        },
        control: {
            fontFamily: 'body',
            fontSize: '1rem',
            lineHeight: '1.5rem',
        },
        company: {
            fontFamily: 'heading',
            fontSize: '1rem',
            lineHeight: '1.5rem',
            textTransform: 'uppercase',
        },
        table: {
            fontFamily: 'body',
            fontSize: '14px',
            lineHeight: '1.5rem',
        },
    },
    radius: {
        xs: '0.5rem',
        sm: '1rem',
        md: '2rem',
        lg: '2.5rem',
        xl: '3rem',
        xxl: '4rem',
    },
    breakpoints: {
        tablet: { minWidth: 500 },
        hd: { minWidth: 1680 },
        uhd: { minWidth: 2000 },
    },
};

export default theme;
