import { createContext, useReducer, useContext } from 'react';

export const EnvContext = createContext(null);
export const EnvDispatchContext = createContext(null);

type Env = {
    postIds: string[],
    headerHeight: number,
    isServer: boolean,
    scale: number,
};

type EnvAction = {
    type: string,
    value: any,
    key: any,
};

type EnvReducer = (state: Env, action: EnvAction) => Env;

export function EnvProvider({children }): React.ReactElement {
    const [env, dispatch] = useReducer<EnvReducer>(
        envReducer,
        {
            postIds: [],
            headerHeight: 0,
            isServer: true,
            scale: 1,
        },
    );

    return (
        <EnvContext.Provider value={env}>
            <EnvDispatchContext.Provider value={dispatch}>
                {children}
            </EnvDispatchContext.Provider>
        </EnvContext.Provider>
    );
}

export function useEnv() {
    return useContext(EnvContext);
}
  
export function useEnvDispatch() {
    return useContext(EnvDispatchContext);
}

function envReducer(state: Env, action: EnvAction): Env {
    switch (action.type) {
        case 'setHeaderHeight': {
            const newState = { ...state}
            newState.headerHeight = action.value;
            return newState;
        }
        case 'setProp': {
            const newState = { ...state}
            newState[action.key] = action.value;
            return newState;
        }
        case 'addPostId': {
            const newState = { ...state}
            newState.postIds = state.postIds || [];
            newState.postIds.push(action.value);
            return newState;
        }
        case 'removePostId': {
            const newState = { ...state}
            newState.postIds = state.postIds || [];
            newState.postIds = state.postIds.filter((id) => id !== action.value);
            return newState;
        }
        default: {
            return state;
        }
    }
}