import { createContext, useReducer, useContext } from 'react';

import { User, TeamDepth } from '../../types';

type State = TeamDepth | {
    name?: string,
    players?: Array<User | {}>,
};

type Action = {
    type: string,
    team?: TeamDepth,
    players?: User[],
    player?: User,
    index?: number,
};

type Reducer = (state: State, action: Action) => State;

type Provider = {
    children: React.ReactNode,
    team?: TeamDepth,
};

const emptyTeam = {
    name: '',
    players: [{}, {}, {}, {}],
};

export const TeamContext = createContext(null);
export const TeamDispatchContext = createContext(null);

export function TeamProvider({ team: initialTeam, children }: Provider): React.ReactElement {
    const [team, dispatch] = useReducer<Reducer>(
        teamReducer,
        initialTeam || emptyTeam,
    );

    return (
        <TeamContext.Provider value={team}>
            <TeamDispatchContext.Provider value={dispatch}>
                {children}
            </TeamDispatchContext.Provider>
        </TeamContext.Provider>
    );
}

export function useTeam() {
    return useContext(TeamContext);
}
  
export function useTeamDispatch() {
    return useContext(TeamDispatchContext);
}

function teamReducer(state: State, action: Action): State {
    switch (action.type) {
        case 'update-team': {
            return { ...state, ...action.team };
        }
        case 'replace-team': {
            return action.team;
        }
        case 'update-players': {
            const newTeam = { ...state };
            const players = newTeam.players.map((player: User, index: number) => {
                return { ...player, ...action.players[index] };
            });
            newTeam.players = players;
            return newTeam;
        }
        case 'replace-players': {
            const newTeam = { ...state };
            newTeam.players = action.players;
            return newTeam;
        }
        case 'update-player': {
            const newTeam = { ...state };
            const player = {...state.players[action.index], ...action.player};
            newTeam.players[action.index] = player;
            return newTeam;
        }
        case 'replace-player': {
            const newTeam = { ...state };
            const player = action.player;
            newTeam.players[action.index] = player;
            return newTeam;
        }
    }
}