import { createContext, useReducer, useContext } from 'react';

import { User } from '../../types';

type State = User | {};

type Action = {
    type: string,
    user: User,
};

type Reducer = (state: State, action?: Action) => State;

type Provider = {
    children: React.ReactElement,
};

export const UserContext = createContext(null);
export const UserDispatchContext = createContext(null);

export function UserProvider({children }): React.ReactElement<Provider> {
    const [user, dispatch] = useReducer<Reducer>(
        userReducer,
        { isLoading: true },
    );

    return (
        <UserContext.Provider value={user}>
            <UserDispatchContext.Provider value={dispatch}>
                {children}
            </UserDispatchContext.Provider>
        </UserContext.Provider>
    );
}

export function useUser() {
    return useContext(UserContext);
}
  
export function useUserDispatch() {
    return useContext(UserDispatchContext);
}

function userReducer(state: State, action: Action): State {
    switch (action.type) {
        case 'update': {
            return { ...state, ...action.user };
        }
        case 'replace': {
            return action.user;
        }
    }
}
